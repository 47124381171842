@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color:  #231602;
}

.subweb-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Ocupa toda la altura de la ventana */
}

.subweb-navbar {
    width: 80%;
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se ajusten y se envuelvan */
    justify-content: center; /* Centra los elementos en la fila */
    align-items: center;
    background-color: #444; /* Marrón claro */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0 0;
    /* position: fixed; Fija el navbar en la parte superior */
    top: 0; /* Asegura que esté en la parte superior */
    left: 0;
    z-index: 1000; /* Asegura que el navbar esté encima del contenido */
    width: 100%; /* Asegura que ocupe todo el ancho */
    border-radius: 10px;
}

.subweb-logo {
    font-size: 0.5em;
    font-weight: bold;
    color: #f1e3cb; /* Dorado claro */
    transition: transform 0.3s;
    margin-bottom: 0px; /* Espacio debajo del logo en vista móvil */
    cursor: pointer; /* Indica que el logo es clickeable */
}

.subweb-logo:hover {
    transform: scale(1.1);
}

.subweb-nav-option {										 
    padding: 14px 20px;
    cursor: pointer;
    color: #f1e3cb; /* Dorado claro Color Nav letras*/
    position: relative;
    transition: background-color 0.3s, color 0.3s;
    font-weight: bold;
	 
}

.subweb-nav-option:hover {
    background-color: #8d6e63; /* Marrón medio */
    color: #d4973c;
    border-radius: 8px;
}

.subweb-dropdown {
    display: none;
    position: absolute;
    background-color: #f1e3cb; /* Dorado claro */
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1001; /* Asegura que el dropdown esté encima del contenido */
    top: 100%; /* Posiciona el dropdown justo debajo del nav */
    left: 0;
    border-radius: 8px;
    overflow: hidden;
    animation: fadeIn 0.3s;
}

.subweb-nav-option:hover .subweb-dropdown,
.subweb-nav-option:focus-within .subweb-dropdown {
    display: block;
}

.subweb-dropdown-item {
    padding: 12px 16px;
    cursor: pointer;
    color: #6d4c41; /* Marrón claro */
    transition: background-color 0.3s, color 0.3s;
}

.subweb-dropdown-item:hover {
    background-color: #8d6e63; /* Marrón medio */
    color: #d4973c;
}

.subweb-text-display {
  padding: 20px;
  background-color: #2d1b0f; /* Marrón claro */
  color: #f1e3cb; /* Dorado claro */
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Permite el desplazamiento si el contenido es demasiado grande */
  display: flex;
  flex-direction: column; /* Asegura que los elementos internos se distribuyan en columna */
  justify-content: flex-start; /* Ajusta el contenido al principio del contenedor */
  align-items: center;
  margin-top: 20px; /* Espacio adicional para evitar superposición con el navbar */
  min-height: 800px; /* Asegura que el contenedor tenga al menos 600px de altura */
  
}

.pricing-table-container {
  display: flex;
  justify-content: center; /* Centra la tabla horizontalmente */
  padding: 20px; /* Espacio alrededor de la tabla */
  overflow-x: auto; /* Permite el desplazamiento horizontal si la tabla es más ancha */
}

.pricing-table {
  border-collapse: collapse;
  background-color: #2d1b0f; /* Marrón claro */
  border-radius: 8px;
  max-width: 100%; /* Se ajusta al tamaño del contenedor */
  margin: auto; /* Centra la tabla dentro del contenedor */
  overflow: hidden; /* Asegura que no haya desbordamiento */
  display: block; /* Asegura que el contenedor se comporte como bloque */
}

/* Estilo de las celdas de la tabla */
.pricing-table th, .pricing-table td {
  padding: 15px;
  text-align: center;
  color: #d4973c; /* Dorado claro */
  border-bottom: 1px solid #444;
}

.pricing-table th {
  background-color: #444; /* Marrón medio */
  color: #d4973c;
  font-weight: bold;
}


/* Estiliza la barra de desplazamiento en Webkit (Chrome, Safari) */
.pricing-table-container::-webkit-scrollbar {
    height: 8px; /* Altura de la barra de desplazamiento horizontal */
}

.pricing-table-container::-webkit-scrollbar-track {
    background: #2d1b0f; /* Color de fondo de la pista de desplazamiento */
    border-radius: 10px; /* Redondea los bordes de la pista */
}

.pricing-table-container::-webkit-scrollbar-thumb {
    background: #d4973c; /* Color del pulgar de desplazamiento */
    border-radius: 10px; /* Redondea los bordes del pulgar */
}

.pricing-table-container::-webkit-scrollbar-thumb:hover {
    background: #b3762c; /* Color del pulgar cuando se pasa el ratón por encima */
}

/* Estilo para el cuerpo de la tabla (solo si es necesario para el desplazamiento vertical) */
.pricing-table tbody {
    display: block;
    max-height: 500px; /* Ajusta la altura máxima para permitir desplazamiento vertical */
    overflow-y: auto; /* Permite el desplazamiento vertical */
}

/* Estilo de la barra de desplazamiento vertical */
.pricing-table-container::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento vertical */
}

.pricing-table-container::-webkit-scrollbar-track {
    background: #2d1b0f; /* Color de fondo de la pista de desplazamiento vertical */
}

.pricing-table-container::-webkit-scrollbar-thumb {
    background: #d4973c; /* Color del pulgar de desplazamiento vertical */
    border-radius: 10px; /* Redondea los bordes del pulgar */
}

.pricing-table-container::-webkit-scrollbar-thumb:hover {
    background: #b3762c; /* Color del pulgar cuando se pasa el ratón por encima */
}


/* Ajustes para dispositivos móviles */
@media (max-width: 767px) {
  .pricing-table-container {
      padding: 10px; /* Reduce el espacio alrededor de la tabla */
  }

  .pricing-table {
      width: 50%; /* Reduce el ancho de la tabla en dispositivos móviles */
      min-width: 300px; /* Establece un ancho mínimo para la tabla */
  }
}


/* Responsive styles */				  

@media (min-width: 1200px) {
  body {
      padding: 0; /* Elimina el padding en pantallas grandes para que el video ocupe toda la pantalla */
      margin: 0; /* Asegura que no haya márgenes que afecten al diseño */
  }
}

@media (max-width: 1199px) {
  body {
      padding: 0 15px; /* Ajusta el padding en pantallas más pequeñas si es necesario */
  }
}
/* Estilo para dispositivos móviles */
@media (max-width: 767px) {
  body {
      padding: 0 5px; /* Ajusta el padding para pantallas móviles si es necesario */
  }
}
/* Estilo para pantallas muy pequeñas */
@media (max-width: 481px) {
  body {
      padding: 0 2px; /* Reduce aún más el padding para pantallas muy pequeñas */
  }
}


@media (max-width: 767px) and (min-width: 481px) {
    .subweb-navbar {
      
        flex-direction: row; /* Alinea los elementos en fila en pantallas medianas */
        flex-wrap: nowrap; /* No permite el ajuste en pantallas medianas */
        justify-content: space-around; /* Distribuye los elementos de manera uniforme */
    }

    .subweb-nav-option {
        width: auto; /* Ajusta el ancho según el contenido */
        text-align: center;
    }
																								  
	 
}

@media (max-width: 480px) {
  .subweb-navbar {      display: flex; /* Cambia a flexbox para organizar los elementos */
    flex-wrap: wrap; 
      grid-template-columns: repeat(2, 1fr); /* 2 columnas en dispositivos móviles */
      gap: 0px; /* Espacio entre las opciones */
      padding: 5px; /* Espacio alrededor del contenido */
      justify-content: center; /* Centra el contenido */
      
  }

  .subweb-nav-option {
      width: auto; /* Ajusta el ancho según el contenido */
      text-align: center;
      padding: 10px; /* Ajusta el padding si es necesario */
  }

  .subweb-search {
      width: 100px; /* Ajusta el tamaño del campo de búsqueda en pantallas pequeñas */
      margin: 10px 0; /* Espacio arriba y abajo del campo de búsqueda */
  }
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.subweb-logo-img {
  height: auto; /* Mantiene la relación de aspecto de la imagen */
  width: 75%; /* Asegura que la imagen ocupe todo el ancho disponible */
  max-width: 150px; /* Ajusta el ancho máximo para pantallas grandes */
  transform: scale(0.5); /* Reduce el tamaño al 50% */
  opacity: 0; /* Inicialmente invisible */
  animation: fadeIn 1s ease-out forwards; /* Aplica la animación */
  transition: transform 0.3s; /* Agrega una transición suave para el cambio de tamaño */
}

@media (max-width: 767px) {
  .subweb-logo-img {
      max-width: 100px; /* Ajusta el ancho máximo para pantallas más pequeñas */
      transform: scale(0.4); /* Reduce el tamaño al 40% en pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  .subweb-logo-img {
      max-width: 80px; /* Ajusta el ancho máximo para pantallas móviles */
      transform: scale(0.3); /* Reduce el tamaño al 30% en pantallas móviles */
  }
}

/* Animaciones */
@keyframes fadeIn {
  from {
      opacity: 0; /* Comienza con la imagen invisible */
      transform: translateY(-20px); /* Opcional: comienza desplazada hacia arriba */
  }
  to {
      opacity: 1; /* Termina con la imagen completamente visible */
      transform: translateY(0); /* Finaliza en su posición original */
  }
}


/* Navbar oculto */
.subweb-navbar.hidden {
  transform: translateY(-100%); /* Mueve el navbar fuera de la vista */
  transition: transform 0.3s ease-in-out; /* Transición suave para la animación */
}

/* Navbar visible */
.subweb-navbar.visible {
  transform: translateY(0); /* Mueve el navbar a la vista */
}



.cofre-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 120px; /* Agrega espacio interno para evitar que el contenido esté pegado a los bordes */
  overflow: auto; /* Asegura que todo el contenido sea visible */
}

.pricing-table1-header {
  margin: 100px 0;
}

.pricing-table1-list {
  margin: 20px 0;
}

.pricing-table img {
  margin: 20px 0;
}

.responsive-video {
  width: 800px;
  height: 600px;
  object-fit: cover;
  border-radius: 15px;
}

/* Estilos para pantallas pequeñas (hasta 767px) */
@media (max-width: 767px) {
  .responsive-video {
      width: 90%;
      height: auto;
  }
}

/* Estilos para pantallas muy pequeñas (hasta 480px) */
@media (max-width: 480px) {
  .responsive-video {
      width: 90%;
      height: auto;
      border-radius: 10px; /* Ajusta el border-radius si es necesario */
  }
}